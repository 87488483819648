<template>
  <div
    style="max-width: 100%"
    >
    <div
      class="text-center text-h5 font-weight-medium secondary--text"
      >
      <v-icon
        x-large
        color="secondary"
        >
        mdi-storefront
      </v-icon>
    </div>

    <div
      class="text-center text-h5 font-weight-medium secondary--text"
      >
      NUESTRAS TIENDAS
    </div>

    <div
      class="mb-6 secondary mx-auto"
      style="height: 4px; width: 100px"
      >
    </div>

    <div
      v-for="item, i in stores"
      class="mb-6"
      >
      <div
        class="d-flex flex-column flex-md-row"
        style="gap: 12px"
        >
        <v-card
          flat
          class="rounded-lg"
          color="primary"
          :height="$vuetify.breakpoint.mdAndUp ? 200 : '80'"
          :width="$vuetify.breakpoint.mdAndUp ? 180 : '100%'"
          :class="(i % 2 && $vuetify.breakpoint.mdAndUp) ? 'order-md-0 order-0' : 'order-md-1 order-0'"
          >
          <v-card-text
            class="d-flex flex-column align-center justify-center fill-height font-weight-bold white--text text-subtitle-1 text-center"
            >
            {{ item.name || 'Otros' }}
          </v-card-text>
        </v-card>

        <v-slide-group
          style="flex: 1"
          :class="(i % 2 && $vuetify.breakpoint.mdAndUp) ? 'order-md-1 order-1' : 'order-md-0 order-1'"
          >
          <v-slide-item
            v-for="store in item.stores"
            >
            <v-card
              outlined
              class="rounded-lg"
              height="200"
              width="180"
              :to="{ name: 'profile', params: { slug: store.profile.slug }}"
              >
              <v-card-text
                class="d-flex flex-column align-center justify-center fill-height"
                >
                <v-avatar
                  size="100"
                  :color="store.profile.picture ? '' : 'primary'"
                  >
                  <v-img
                    v-if="store.profile.picture"
                    :src="url + store.profile.picture.url"
                    ></v-img>

                  <v-icon
                    v-else
                    color="white"
                    x-large
                    >
                    mdi-storefront
                  </v-icon>
                </v-avatar>

                <div
                  class="store-card-box body-1 black--text mt-2 font-weight-medium primary--text"
                  style="max-width: 100%"
                  >
                  {{ store.name }}
                </div>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Stores } from '@/graphql/queries/fairs/fairs'

export default {
  data: () => ({
    stores: []
  }),

  props: {
    fair: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchStores()
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    fetchStores () {
      this.$apollo.query({
        query: Stores,
        variables: {
          id: this.fair.id
        }
      }).then ( res => {
        this.stores = res.data.storesInFair
      })
    }
  },
}
</script>

<style scoped>
>>> .v-slide-group__content {
  gap: 8px !important
}
</style>
